import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { ApolloError } from '@apollo/client/errors';

import { ContextService } from 'src/services/context.service';
import { ManningDataService } from 'src/services/manning-data.service';
import { AuthService, IAccount } from 'src/services/auth.service';
import { SentryService } from 'src/services/sentry.service';
import { UIService } from 'src/services/ui.service';
import { LoggingService } from 'src/services/logging.service';
import { Right, Scope, deepClone } from 'src/commontypes/util';
import { calculateManningContracts, ManningPerson, getManningStatusCodes, ManningContract } from 'src/commontypes/manning';
import { environment } from 'src/environments/environment';

import { ITableDefinition } from 'src/components/display-table/display-table.component';

const defaultTableCols = [
  {
    label: 'T&A ID',
    value: 'personnelNo',
    sort: true,
    filter: 'text',
  },
  {
    label: 'Check In ID (Merlin)',
    value: 'checkInId',
    sort: true,
    filter: 'text',
  },

  {
    label: 'First Name',
    value: 'firstName',
    sort: true,
    filter: 'text',
  },
  {
    label: 'Last Name',
    value: 'lastName',
    sort: true,
    filter: 'text',
  },
  {
    label: 'Department',
    value: 'activeDepartmentLabel',
    sort: true,
    filter: 'text',
  },
  {
    label: 'Other Hotels',
    value: 'otherHotelsLabel',
    sort: true,
    filter: 'text',
    clusterOnly: true,
  },
  {
    label: 'Job Role',
    value: 'activeRoleLabel',
    sort: true,
    filter: 'text',
  },
  {
    label: 'Business Title',
    value: 'activeTitle',
    sort: true,
    filter: 'text',
  },
  {
    label: 'Job Band',
    value: 'activeJobBand',
    sort: true,
    filter: 'text',
  },
  {
    label: 'Weekly Contracted Hours',
    value: 'activeContractHours',
    sort: true,
    filter: 'text',
  },
  {
    label: 'Annual Leave (Days)',
    value: 'holidayDays',
    sort: true,
    filter: 'text',
  },
  {
    label: 'Status',
    value: 'statusText',
    sort: true,
    filter: 'text',
    defaultFilter: 'Active',
  },
  {
    label: 'User Account',
    value: 'accountStatus',
    sort: true,
    filter: 'text',
  },
  {
    label: 'User Account Enabled',
    value: 'accountEnabled',
    sort: true,
    filter: 'text',
  },
];
@Component({
  selector: 'app-manning',
  styleUrls: ['./manning.component.scss'],
  templateUrl: './manning.component.html',
})
export class ManningComponent implements OnInit, OnDestroy {
  loading = false;
  canEditUser = false;
  canViewUser = false;
  isSystemAdmin = false;
  _canEditEmployee = false;
  editUser$: Subscription;
  viewUser$: Subscription;
  editEmployee$: Subscription;
  hotelWatch$: Subscription;
  checkInIdValid = true;
  loadingCheckInId = false;
  editPerson: ManningPerson & { isHomeHotel: boolean } = null;
  persons: Array<ManningPerson & { isHomeHotel: boolean }> = null;
  account: IAccount;
  savingEdit = false;
  statusCodes = getManningStatusCodes();
  currentHotel;
  useAccount = false;
  accountValid = false;
  accountOptions = [
    { label: 'Off', value: false },
    { label: 'On', value: true },
  ];
  cluster?: { name: string; id: string; hotels: Array<{ id: string; name: string; holidexCode: string }> };
  clusterHotels?: Array<{ id: string; label: string; holidexCode: string }>;
  homeHotel?: string;
  get canEditEmployee() {
    if (this.editPerson) {
      return this._canEditEmployee && this.editPerson.isHomeHotel;
    }
    return this._canEditEmployee;
  }

  tblDef: ITableDefinition = {
    showAdd: true,
    addLabel: 'Add Employee',
    colFilters: true,
    helpfiles: [{ file: 'employee_instruction', description: 'Instructions' }],
    cols: defaultTableCols,
  };

  constructor(
    private contextService: ContextService,
    private manningData: ManningDataService,
    private uiService: UIService,
    private authService: AuthService,
    private log: LoggingService,
    private sentryService: SentryService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loading = true;
    this.hotelWatch$ = this.contextService.getCurrentBasicHotel$().subscribe((hotel) => {
      if (!hotel || !hotel.id) return;
      this.editPerson = null;
      this.currentHotel = hotel;
      this.cluster = hotel.cluster;
      if (this.cluster && this.cluster.hotels.length > 0) {
        this.tblDef.cols = defaultTableCols;
      } else {
        this.tblDef.cols = defaultTableCols.filter(({ clusterOnly }) => !clusterOnly);
      }
      this.clusterHotels = this.cluster?.hotels.map(({ id, name, holidexCode }) => ({
        id,
        holidexCode,
        label: `${holidexCode} - ${name}`,
      }));
      this.persons = null;
      this.loading = false;
      this.route.paramMap.pipe(first()).subscribe((params) => {
        const manningId = params.get('personId');
        this.loadPersons(manningId?.length > 0 ? Number(manningId) : undefined);
      });
    });
    this.editUser$ = this.authService
      .hasAnyRoles([
        [Scope.USER, Right.WRITE],
        [Scope.SCHEDULE_ADMIN, Right.WRITE],
        [Scope.SCHEDULE_USER, Right.WRITE],
      ])
      .subscribe((canEditUser) => {
        this.canEditUser = canEditUser;
      });
    this.viewUser$ = this.authService
      .hasAnyRoles([
        [Scope.USER, Right.READ],
        [Scope.SCHEDULE_ADMIN, Right.READ],
        [Scope.SCHEDULE_USER, Right.READ],
      ])
      .subscribe((canViewUser) => {
        this.canViewUser = canViewUser;
      });
    this.editEmployee$ = this.authService
      .hasAnyRoles([
        [Scope.REGION_ADMIN, Right.WRITE],
        [Scope.SCHEDULE_ADMIN, Right.WRITE],
        [Scope.SCHEDULE_MANNING, Right.WRITE],
      ])
      .subscribe((canEditEmployee) => {
        this.tblDef.showAdd = canEditEmployee;
        this._canEditEmployee = canEditEmployee;
      });
    this.editEmployee$ = this.authService.hasAnyRoles([[Scope.REGION_ADMIN, Right.WRITE]]).subscribe((isSystemAdmin) => {
      this.isSystemAdmin = isSystemAdmin;
    });
  }

  ngOnDestroy(): void {
    if (this.hotelWatch$) {
      this.hotelWatch$.unsubscribe();
    }
    if (this.editUser$) {
      this.editUser$.unsubscribe();
    }
    if (this.viewUser$) {
      this.viewUser$.unsubscribe();
    }
    if (this.editEmployee$) {
      this.editEmployee$.unsubscribe();
    }
  }

  createHotelOtherLabels(contracts: Array<{ department: { hotelId? }; otherDepartments?: Array<{ hotelId? }> }>) {
    const holidexes = [];
    this.log.debug('createHotelOtherLabels', this.clusterHotels);
    contracts.forEach((contract) => {
      if (contract.otherDepartments && contract.otherDepartments.length > 0) {
        holidexes.push(
          ...contract.otherDepartments
            .filter((dept) => dept && dept.hotelId !== this.currentHotel?.id)
            .map((dept) => this.clusterHotels?.find((hotel) => dept.hotelId === hotel.id)?.holidexCode)
            .filter((holidexCode) => !!holidexCode)
        );
      }
    });
    return Array.from(new Set(holidexes.filter((h) => !!h))).join(', ');
  }

  loadPersons(showPersonWithId?: number) {
    if (this.currentHotel) {
      this.loading = true;
      this.manningData
        .getPersons(this.currentHotel.id)
        .pipe(first())
        .subscribe(
          (data) => {
            if (data) {
              this.persons = data.map(({ contracts, ...person }) => ({
                ...person,
                contracts: contracts?.map((c) => ({
                  ...c,
                  jobBand: (((c.jobBand > 0 && c.jobBand <= 10) || c.jobBand === 999) && c.jobBand) || null,
                })),
                isHomeHotel: this.currentHotel?.id === person.hotelId,
                otherHotelsLabel: person.contracts ? this.createHotelOtherLabels(person.contracts) : '',
              }));
              if (showPersonWithId > 0) {
                const person = this.persons.find((p) => Number(p.id) === showPersonWithId);
                if (person) {
                  this.editPerson = {
                    ...deepClone(person),
                    isHomeHotel: this.currentHotel.id === person.hotelId,
                  };
                }
              }
            } else {
              this.persons = null;
              this.editPerson = null;
            }
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    }
  }

  addRow() {
    this.useAccount = false;
    this.checkInIdValid = true;
    this.editPerson = {
      accountId: null,
      hasAccount: false,
      accountStatus: 'No',
      accountEnabled: 'No',
      allContractsValid: false,
      checkInId: undefined,
      id: null,
      personnelNo: this.currentHotel?.holidexCode,
      firstName: '',
      lastName: '',
      email: '',
      holidayDays: this.currentHotel?.holidayEntitlementPerYear,
      publicHolidayDays: this.currentHotel?.publicHolidaysPerYear,
      hotelId: this.currentHotel?.id,
      holidexCode: this.currentHotel?.holidexCode,
      sickDays: 0,
      contracts: [],
      activeContract: null,
      leaving: false,
      statusText: 'Active',
      isHomeHotel: true,
      agency: false,
    };
    if (this.clusterHotels) {
      this.homeHotel = this.currentHotel?.id;
    } else {
      this.homeHotel = null;
    }
  }

  editRow(person: ManningPerson) {
    if (person.accountId) {
      if (this.canEditUser || this.canViewUser) {
        this.loading = true;
        this.manningData
          .getAccount(person.accountId)
          .pipe(first())
          .subscribe(
            (account) => {
              this.loading = false;
              this.account = { ...account, confirmEmail: account.email };
              this.useAccount = true;
              this.editPerson = {
                ...deepClone(person),
                isHomeHotel: this.currentHotel.id === person.hotelId,
              };
              this.checkInIdChanged();
              if (this.clusterHotels) {
                this.homeHotel = person.hotelId;
              } else {
                this.homeHotel = null;
              }
            },
            (error) => {
              this.loading = false;
              if (error?.message === 'Unknown account ID') {
                this.sentryService.sendMessage('Unable to retrieve account for employee', 'warning', {
                  personId: person.id,
                  accountId: person.accountId,
                  error,
                });
                this.uiService.info('Invalid account associated with employee, will clear it');
                this.account = undefined;
                this.useAccount = false;
                this.editPerson = {
                  ...deepClone(person),
                  isHomeHotel: this.currentHotel.id === person.hotelId,
                };
                this.checkInIdChanged();
              } else {
                this.sentryService.showAndSendError(error, 'Internal error - account', 'Unable to retrieve account details.', {
                  accountId: person?.accountId,
                });
              }
            }
          );
      } else {
        this.useAccount = true;
        this.editPerson = {
          ...deepClone(person),
          isHomeHotel: this.currentHotel.id === person.hotelId,
        };
        this.checkInIdChanged();
        if (this.clusterHotels) {
          this.homeHotel = person.hotelId;
        } else {
          this.homeHotel = null;
        }
      }
    } else {
      this.editPerson = {
        ...deepClone(person),
        isHomeHotel: this.currentHotel.id === person.hotelId,
      };
      this.checkInIdChanged();
      if (this.clusterHotels) {
        this.homeHotel = person.hotelId;
      } else {
        this.homeHotel = null;
      }
    }
  }

  addContract() {
    const newContract = {
      id: null,
      hotelId: null,
      role: null,
      jobBand: null,
      payRate: null,
      department: null,
      otherDepartments: null,
      start: new Date(),
      end: undefined,
      contractedHours: this.currentHotel?.baseContractHoursPerWeek,
      workDays: this.currentHotel?.workDaysPerWeek,
      casual: false,
      title: null as string,
      isValid: false,
      isActive: false,
    };
    // check if there is a incomplete undeleted contract and replace it otherwise add a new contract
    const index = this.editPerson.contracts.findIndex((c) => c.id === 'delete');
    if (index >= 0) {
      this.editPerson.contracts[index] = newContract;
    } else {
      this.editPerson.contracts.push(newContract);
    }
    this.editPerson = { ...calculateManningContracts(this.editPerson), isHomeHotel: this.editPerson.isHomeHotel };
    this.editPerson.contracts[this.editPerson.contracts.length - 1].overlap = false; // this is a new one so ignore the overlap until it's changed
  }

  removePerson() {
    if (this.account?.id) {
      if (this.canEditUser) {
        this.uiService.acknowledgeAction(
          'Please switch off system access and save before removing the employee.',
          undefined,
          'Employee has system access'
        );
      } else {
        this.uiService.acknowledgeAction(
          'You do not have rights to remove the employee system access, please terminate their contract instead.',
          undefined,
          'Insufficient permissions'
        );
      }
    } else if (this.isSystemAdmin) {
      this.uiService.confirmActionDanger('Remove this Employee?', 'Remove', () => {
        this.savingEdit = true;
        const { id } = this.editPerson;
        this.editPerson = null;
        this.manningData
          .deletePerson(id, true)
          .pipe(first())
          .subscribe(
            () => {
              //okay we removed the person from the back end
              this.persons = [...this.persons.filter((person) => person.id !== id)];
              this.savingEdit = false;
            },
            (err) => {
              this.sentryService.showAndSendError(err, 'Internal error - employees', 'Unable to delete employee, please reload.');
              this.savingEdit = false;
            }
          );
      });
    } else {
      this.uiService.acknowledgeAction(
        'You do not have rights to remove the employee, please terminate their contract instead',
        undefined,
        'Insufficient permissions'
      );
    }
  }

  async validateContractShifts(personId: string, con: ManningContract) {
    if (!personId) return true;
    return new Promise((resolve, reject) => {
      var allowedDepartments = [con.department, ...con.otherDepartments];
      this.manningData
        .getPersonFutureShifts(personId)
        .pipe(first())
        .subscribe((data) => {
          if (!data) return resolve(true); //no bad shifts - good

          const badShifts = data.filter((s) => {
            return !allowedDepartments.some((d) => {
              if (d.name != s.departmentName) return false;
              if (d.outletType) {
                if (d.outletType != s.outletType) return false;
                if (d.outletIndex != s.outletIndex) return false;
              }
              if (d.subDeptName) {
                if (d.subDeptName != s.subDeptName) return false;
              }
              return true;
            });
          });

          if (!badShifts?.length) return resolve(true); //no bad shifts - good
          const badShiftText = badShifts.map((s) => s.departmentName + ' (' + s.startDay + ')').join('; ');
          this.uiService.acknowledgeError(
            'Changes to this employees active contract cannot be saved as there are future shifts scheduled for these departments:\n ' +
              badShiftText,
            () => resolve(false),
            'Future Shifts are Illegal'
          );
          resolve(false);
        });
    });
  }

  async savePerson() {
    this.savingEdit = true;

    const save = () => {
      if (this.editPerson.isHomeHotel || !this.editPerson.id) {
        this.manningData
          .savePerson(
            {
              ...this.editPerson,
              hotelId: this.homeHotel || this.editPerson.hotelId || this.currentHotel.id,
              contracts: this.editPerson.contracts.filter((c) => !c.deleteMe),
            },
            !this.useAccount,
            this.account,
            !this.canEditUser
          )
          .pipe(first())
          .subscribe(
            (data) => {
              const newPerson = { ...data, otherHotelsLabel: data.contracts ? this.createHotelOtherLabels(data.contracts) : '' };
              const index = this.persons.findIndex((per) => per.id === newPerson.id);
              if (index < 0) {
                this.persons = [{ ...newPerson, isHomeHotel: newPerson.hotelId === this.currentHotel.id }, ...this.persons];
              } else {
                this.persons = [
                  ...this.persons.slice(0, index),
                  { ...newPerson, isHomeHotel: newPerson.hotelId === this.currentHotel.id },
                  ...this.persons.slice(index + 1),
                ];
              }
              this.savingEdit = false;
              this.account = undefined; // clear old data
              this.editPerson = null; // kill the editor
            },
            (err) => {
              if (err instanceof ApolloError) {
                if (
                  err.graphQLErrors &&
                  err.graphQLErrors.some(({ message }) =>
                    String(message).startsWith('A different person with that personnel number already exists')
                  )
                ) {
                  this.uiService.acknowledgeError('An employee with this T&A ID already exists and cannot be added again.');
                  this.savingEdit = false;
                  return;
                }
              }
              this.sentryService.showAndSendError(err, 'Internal error - employees', 'Unable to update employee, please reload.');
              this.savingEdit = false;
            }
          );
      } else {
        this.manningData.saveAccount(this.account, { personId: this.editPerson.id, hotelId: this.currentHotel.id }).subscribe(
          () => {
            this.savingEdit = false;
            this.editPerson = null; //kill the editor
          },
          (err) => {
            if (err instanceof ApolloError) {
              if (
                err.graphQLErrors &&
                err.graphQLErrors.some(({ message }) =>
                  String(message).startsWith('A different person with that personnel number already exists')
                )
              ) {
                this.uiService.acknowledgeError('An employee with this T&A ID already exists and cannot be added again.');
                this.savingEdit = false;
                return;
              }
            }
            this.sentryService.showAndSendError(err, 'Internal error - employees', 'Unable to update employee, please reload.');
            this.savingEdit = false;
          }
        );
      }
    };

    if (!(await this.validateContractShifts(this.editPerson.id, this.editPerson.activeContract))) {
      this.savingEdit = false;
      return;
    }

    // end any contract that is open all terminated beyond this date
    if (this.editPerson.leaving) {
      for (var con of this.editPerson.contracts) {
        if (con.isOpen || con.end > this.editPerson.leavingDate) {
          con.end = this.editPerson.leavingDate;
        }
      }
    }

    const createNameKey = ({ firstName, lastName }: { firstName: string; lastName: string }) =>
      `${firstName.toLowerCase()}${lastName.toLowerCase()}`.replace(/[^\w]/gi, '');
    const newPersonKey = createNameKey(this.editPerson);
    const possibleDuplicate =
      !this.editPerson.id &&
      this.persons.find((p) => newPersonKey === createNameKey(p) && (!this.editPerson.id || this.editPerson.id !== p.id));
    const terminatedString = possibleDuplicate?.statusText?.startsWith('Terminated') ? 'terminated employee ' : '';

    const obs = this.editPerson.checkInId
      ? this.manningData.getCheckInIdPerson(this.editPerson.checkInId).pipe(
          first(),
          mergeMap((data) => {
            if (data && data.id !== this.editPerson?.id) {
              this.uiService.acknowledgeError(
                `Employee ${data.personnelNo} (${data.status}) in this hotel is already using check in ID "${this.editPerson.checkInId}". ` +
                  `Please update or remove the duplicate check in IDs if you wish to proceed.`
              );
              return of(false);
            } else {
              return of(true);
            }
          }),
          catchError(() => {
            this.uiService.acknowledgeError(`Unable to retrieve check in IDs, please reload and try again.`);
            return of(false);
          })
        )
      : of(true);

    obs.pipe(first()).subscribe((validCheckInId) => {
      if (validCheckInId) {
        if (this.canEditUser && this.useAccount && !this.account?.id && this.account?.email) {
          const confirmAccountAdd = () =>
            this.uiService.confirmAction(
              `Please confirm that you want to add a login for ${this.account.email}. They will be sent an email invite.`,
              save,
              () => (this.savingEdit = false)
            );
          if (possibleDuplicate) {
            this.uiService.confirmActionDanger(
              `A ${terminatedString}"${possibleDuplicate.firstName} ${possibleDuplicate.lastName}" already exists in department "${possibleDuplicate.activeDepartmentLabel}". Do you want to continue adding this employee?`,
              'Add employee',
              confirmAccountAdd,
              () => (this.savingEdit = false)
            );
          } else {
            confirmAccountAdd();
          }
        } else if (this.canEditUser && !this.useAccount && this.account?.id) {
          this.uiService.confirmActionDanger(
            `Do you want to remove ${this.account.email}'s access from ${this.editPerson.firstName} ${this.editPerson.lastName}.`,
            'Remove access',
            save,
            () => (this.savingEdit = false)
          );
        } else if (this.canEditUser && this.useAccount && this.account?.id && !this.account.enabled) {
          const confirmAccountAdd = () =>
            this.uiService.confirmAction(
              `Please confirm that you want to enabled access for ${this.account.email}.`,
              save,
              () => (this.savingEdit = false)
            );
          if (possibleDuplicate) {
            this.uiService.confirmActionDanger(
              `A ${terminatedString}"${possibleDuplicate.firstName} ${possibleDuplicate.lastName}" already exists in department "${possibleDuplicate.activeDepartmentLabel}". Do you want to continue enabling this employee?`,
              'Add employee',
              confirmAccountAdd,
              () => (this.savingEdit = false)
            );
          } else {
            confirmAccountAdd();
          }
        } else if (possibleDuplicate) {
          this.uiService.confirmActionDanger(
            `A ${terminatedString}"${possibleDuplicate.firstName} ${possibleDuplicate.lastName}" already exists in department "${possibleDuplicate.activeDepartmentLabel}". Do you want to continue adding this employee?`,
            'Add employee',
            save,
            () => (this.savingEdit = false)
          );
        } else {
          save();
        }
      } else {
        this.savingEdit = false;
      }
    });
  }

  cancelPerson() {
    this.editPerson.contracts.map((c) => ({ ...c, deleteMe: false }));
    this.editPerson = null;
    this.account = undefined;
    this.checkInIdValid = true;
  }

  get contracts() {
    if (this.editPerson) {
      return this.editPerson.contracts.filter((c) => !c.deleteMe);
    }
    return [];
  }

  contractRemoved(contract) {
    this.savingEdit = true;
    if (this.editPerson && contract) {
      // contracts which don't have a valid ID will me marked as 'delete'
      const index =
        contract.id === 'delete'
          ? this.editPerson.contracts.findIndex((c) => c.id === 'delete')
          : this.editPerson.contracts.findIndex((c) => c.id === contract.id);
      if (index >= 0) {
        this.editPerson.contracts[index].deleteMe = true;
        this.editPerson = { ...calculateManningContracts(this.editPerson), isHomeHotel: this.editPerson.isHomeHotel };
      }
    }
    this.savingEdit = false;
  }

  contractsChanged() {
    if (this.editPerson) {
      this.editPerson = { ...calculateManningContracts(this.editPerson), isHomeHotel: this.editPerson.isHomeHotel };
    }
  }

  accountValidityChanged(valid) {
    this.accountValid = valid;
  }

  employeeValid() {
    const holidexCode = this.homeHotel
      ? this.clusterHotels.find(({ id }) => id === this.homeHotel)?.holidexCode
      : this.currentHotel?.holidexCode;
    return (
      this.editPerson.personnelNo && //is entered
      !/\s/g.test(this.editPerson.personnelNo) && //has no spaces
      this.editPerson.personnelNo.length >= 8 && //length >=8
      this.editPerson.personnelNo.startsWith(holidexCode) && //starts with holidex
      (this.editPerson.leaving ? !!this.editPerson.leavingDate : true)
    );
  }

  switchHotel(hotelId) {
    if (hotelId) {
      this.contextService.setCurrentHotelById(hotelId);
    }
  }

  checkInIdChanged() {
    this.checkInIdValid =
      !this.editPerson.checkInId ||
      (this.editPerson.checkInId?.length >= 6 && this.editPerson.checkInId?.length <= 10 && /^[a-z0-9]+$/i.test(this.editPerson.checkInId));
  }
}
