import credentials from './credentials.json';

export const environment = {
  logLevel: 2, // 0 = error, 1 = info, 2 = debug, -1 = none
  production: false,
  sentryDsn: null,
  sentryTracingOrigins: ['http://localhost', 'https://api-dev.ihgwot-stg.page'],
  sentrySampleRate: 1.0,
  sentryEnv: 'develop',
  release: 'dev',
  version: '{{POST_BUILD_SCRIPT_ENTERS_VERSION_HERE}}',
  hash: '{{POST_BUILD_SCRIPT_ENTERS_HASH_HERE}}',
  buildDate: '{{POST_BUILD_SCRIPT_ENTERS_BUILD_DATE_HERE}}',
  versionDelay: 1000 * 60 * 5,
  ignoreUnavailable: false,
  internalAuthorizationExpiryCheck: true,
  internalPasswordExpiryCheck: true,
  retryConfig: {
    serverErrorRetries: 1, // on develop we want error to show up
    otherErrorRetries: 1, // on develop we want error to show up
    throttleErrorRetries: 6,
    maxThrottleDelay: 15000,
    maxErrorRetries: 40,
    initialDelay: 500,
    maxDelay: 4000,
  },
  shiftOptimalValuesPollInterval: 60 * 1000,
  useSSO: false,
  login: {
    autoSSO: true,
    maxLoginErrorCount: 4,
    errorClearTimeoutMs: 30 * 1000,
  },
  firebaseConfig: {
    apiKey: credentials.apiKey,
    authDomain: 'ihg-bef-online-dev.firebaseapp.com',
    projectId: 'ihg-bef-online-dev',
    storageBucket: 'ihg-bef-online-dev.appspot.com',
    messagingSenderId: '1048185130467',
    appId: '1:1048185130467:web:558c2e45d6d12afdfd24d6',
  },
  ssoConfig: {
    issuer_uri: 'https://myfederate-qa.ihg.com',
    client_id: 'IHG-WOT-Stg',
    client_secret: 'OCs5wye7h7o8jcAVLZLrU39P1Atud3Kku6MpEby3RJXasvYanAu01cyU2hXxTxJt',
    redirect_uri: 'https://tp-dev.ihg-wo.com/login',
    extras: {
      prompt: 'consent',
      access_type: 'offline',
    },
  },
  toolsUrl: 'https://tp-dev.ihg-wo.com',
  adminUrl: 'https://tp-dev.ihg-wo.com',
  wotUrl: 'https://ls-dev.ihg-wo.com',
  webUrl: 'https://tp-dev.ihg-wo.com',
  checkInUrl: 'https://ihg-checkin-dev.firebaseapp.com',
  graphQLUrl: 'https://api-dev.ihg-wo.com/dev-api-core',
  graphQLLocalCoreUrl: undefined,
  graphQLLocalRegionUrl: undefined,
  authTiming: {
    checkIntervalMs: 1000 * 60 * 1,
    expiryWarningMs: 1000 * 60 * 5,
    refreshTimeoutMs: 1000 * 60 * 3,
    refreshThrottleMs: 1000 * 60,
  },
  logout: {
    checkIntervalMs: 30000,
    activeTimeoutMs: 30 * 60 * 1000,
    savedLogoutTimeoutMs: 1000 * 60 * 24 * 7,
    gracePeriodMs: 10000,
  },
  appCheckKey: undefined,
  names: {
    bef: 'Labour Standards',
    befFull: 'WO Labour Standards',
    befShort: 'LS',
    scheduler: 'Team Planner',
    schedulerFull: 'WO Team Planner',
    schedulerShort: 'TP',
  },
  // feature flags
  useSchedulerPasswordChange: false,
  useDeviceCheckIn: true,
  usePayRate: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
